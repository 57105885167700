<template>
	<div class="about">
		<carousel
			:nav = true
			:centerMode = true
			:autoPlay = true
			:playSpeed = 8000
			:dtImg = banner
		/>
		<tabMenu 
			:aboutData=aboutLang 
			:lang=lang
			:awards=awards
		/>
	</div>
</template>

<script>
// @ is an alias to /src
import carousel from "@/components/carousel.vue";
import tabMenu from "@/components/about/TabMenu.vue";
import { mapState } from 'vuex';
export default {
	name: "About",
	components: {
		carousel,
		tabMenu
	},
	mounted() {
		this.getAwards()
	},
	computed: {
        ...mapState({
            data: state => state.home.home ? state.home.home.data :'',
			awards: state => state.about.awards ? state.about.awards.data :'',
			lang: state => state.home.lang ? state.home.lang :'id',
        }),

		aboutLang(){
			let aboutTab_id = {}
			let aboutTab_eng= {}

			if (this.lang == 'id'){
				Object.assign(aboutTab_id, 
					{history: this.data.history},
					{vision: this.data.vision},
					{mission: this.data.mission},
					{corporate: this.data.corporate},
					{organization: this.data.organization},
					{awards: this.data.awards},
					{journey: this.data.journey},
					
				);
				return aboutTab_id
			}else{
				Object.assign(aboutTab_eng, 
					{history: this.data.history_eng},
					{vision: this.data.vision_eng},
					{mission: this.data.mission_eng},
					{corporate: this.data.corporate_eng},
					{organization: this.data.organization_eng},
					{awards: this.data.awards_eng},
					{journey: this.data.journey_eng},
				);
				return aboutTab_eng
			}
		},

		banner(){
			return this.$store.getters['image/imgWtFilter']('about_banner')
		}
    },
	methods: {
		async getAwards(){

            try {
                await this.$store.dispatch('about/getAwards')
				await this.$store.dispatch('about/getAwardsYears')
            } catch (error) {
                console.log(error)
            }


        }
	},
}
</script>
