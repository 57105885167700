<template>
    <div class="-mt-2 md:mt-0 mb-9">
        <div class="bg-grays-100 md:h-16 text-grays-400 w-full h-14 overflow-x-scroll">
            <ul class="flex justify-between items-center h-full lg:px-32 m-auto">
                <li 
                    v-for="(item, index) in tabMenu" 
                    :key="index"
                    class="px-2 mx-3 capitalize font-pop text-xs lg:text-sm text-grays-400 font-semibold w-40 h-full flex items-center cursor-pointer duration-100 justify-center border-b-4 border-solid border-grays-200 hover:border-primary hover:text-primary flex-wrap"
                    :class="{'activeTab' : index == tabActive }"
                    @click="handleTab(index)"
                >
                    
                    <span v-if="lang == 'id'">{{item.name}}</span>
                    <span v-else>{{item.name_eng}}</span>
                </li>
            </ul>
        </div>

        <section class="my-4 p-3 lg:px-32 min-h-10 mb-40 max-w-screen-xl m-auto" >
            <div 
                class="w-full lg:mx-3 text-left sec-history"
                v-if="tabActive == 0">
                <div class="font-semibold text-base md:text-xl mb-6">
                    {{ $t('about.history.our') }}
                </div>
                <progressive-img
                    :src="imgHistory[0] ? imgHistory[0].image : imgPreload"
                    :placeholder='imgPreload'
                    :blur=30
                />
                
                <div class="text-xs" v-if="imgHistory[0] ? imgHistory[0].caption : '' ">
                    <span v-if="lang =='id'">{{imgHistory[0].caption}}</span>
                    <span v-else>{{imgHistory[0].caption_eng}}</span>
                </div>

                <div class="mt-10 text-sm lg:text-base text-justify">
                    <div v-html="aboutData.history"></div>
                </div>
                
               
            </div>
            <div class="w-full lg:mx-3 text-left"  v-if="tabActive == 1">
                
                <h2 class="text-base md:text-xl font-semibold mb-6">
                    {{ $t('about.visi.title1') }}
                </h2>
                <div class="flex justify-between flex-col">
                    <div class="w-full flex items-center">
                        <progressive-img
                            :src="imgVimi[0] ? imgVimi[0].image : imgPreload"
                            :placeholder="imgPreload"
                            :blur=30
                            class="rounded-md"
                        />
                    </div>
                    <div class="text-xs" v-if="imgVimi[0] ? imgVimi[0].caption : ''">
                        <span v-if="lang =='id'">{{imgVimi[0].caption}}</span>
                        <span v-else>{{imgVimi[0].caption_eng}}</span>
                    </div>
                    <div class="w-full  mt-10">
                        <div class="md:mb-14 mb-6 dvVision" v-html="aboutData.vision">
                           
                        </div>
                        <div class="p-0 md:p-0 dvMission" v-html="aboutData.mission">
                           
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="w-full lg:mx-3 text-left" v-if="tabActive == 2">
                <!-- <h1 class="text-sm md:text-base">
                    {{ $t('about.corporate.cop') }}
                </h1> -->
                <h2 class="text-base md:text-xl font-semibold">
                    {{ $t('about.corporate.succes') }}
                </h2>
                <div class="my-5">
                    <progressive-img
                        :src="imgCorporate[0] ? imgCorporate[0].image : imgPreload"
                        :placeholder="imgPreload"
                        :blur=30
                    />
                    <p class="text-xs" v-if="imgCorporate[0] ? imgCorporate[0].caption : ''">
                        <span v-if="lang =='id'">{{imgCorporate[0].caption}}</span>
                        <span v-else>{{imgCorporate[0].caption_eng}}</span>
                    </p>
                </div>
                <div class="text-sm md:text-base mt-10 dvCoporate" v-html="aboutData.corporate">
                    
                </div>
            </div>
            <div class="w-full lg:mx-3 text-left" v-if="tabActive == 3">
                <h1 class="md:text-2xl text-base font-semibold"> {{ $t('about.organization.structure') }}</h1>
                <div class="mt-5 mb-10">
                    <progressive-img
                        :src="imgOrz[0] ? imgOrz[0].image : imgPreload"
                        :placeholder="imgPreload"
                        :blur=30
                    />
                    <p class="text-xs" v-if="imgOrz[0] ? imgOrz[0].caption : ''">
                        <span v-if="lang =='id'">{{imgOrz[0].caption}}</span>
                        <span v-else>{{imgOrz[0].caption_eng}}</span>
                    </p>
                </div>
                <div v-html="aboutData.organization" class="dvOrgz text-sm md:text-base mt-5 text-justify">

                </div>
            </div>
            <div class="w-full lg:mx-3 text-left" v-if="tabActive == 4">
                <h1 class="text-xl font-semibold mb-5">
                    {{ $t('about.awards.for') }}
                </h1>
                <p class="text-sm md:text-base" v-html="aboutData.awards">
                </p>

                <section class="flex w-full px-0  mt-10">
                    <!-- <div class="h-56 overflow-hidden w-full md:w-5/12 lg:w-1/2 p-0 lg:p-3  mb-20 md:mb-15 lg:mb-10" v-for="(item, i) in awards" :key=i>
                        <div class="bg-grays-200 text-sm rounded-t-sm pl-5 flex items-center h-9">
                            <span v-if="lang == 'id'"> {{item.title1}}</span>
                            <span v-else>{{item.title1_eng}}</span>
                        </div>
                        <div class="bg-grays-100 flex justify-between  rounded-b-sm w-full h-full">
                            <img src="@/assets/img/award.svg" class="md:w-20 w-24 pl-4 br" alt="">
                            <div class="p-5 md:p-5 lg:p-6 flex flex-col leading-normal">
                                <h4 class="text-base font-bold mb-2">
                                    <span v-if="lang == 'id'"> {{item.title2}}</span>
                                    <span v-else>{{item.title2_eng}}</span>
                                </h4>
                                <p class="text-base md:text-sm overflow-scroll pb-3">
                                    <span v-if="lang == 'id'"> {{item.description}}</span>
                                    <span v-else>{{item.description_eng}}</span>
                                </p>
                            </div>
                        </div>
                    </div> -->

                    <card
                        :dtCard="awards"
                    />
                </section>
               
                
            </div>
            <div class="w-full lg:mx-3 text-left" v-if="tabActive == 5">
                <h1 class="md:text-2xl text-base font-semibold">{{ $t('about.journey.title') }}</h1>
                <div class="mt-5 mb-10">
                    <progressive-img
                        :src="imgJourney[0] ? imgJourney[0].image : imgPreload"
                        :placeholder="imgPreload"
                        :blur=30
                    />
                     <p class="text-xs" v-if="imgJourney[0] ? imgJourney[0].caption : ''">
                        <span v-if="lang =='id'">{{imgJourney[0].caption}}</span>
                        <span v-else>{{imgJourney[0].caption_eng}}</span>
                    </p>
                </div>
                <div v-html="aboutData.journey" class="dvOrgz text-sm md:text-base mt-5 text-justify">

                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    props:[
        'aboutData',
        'awards',
        'lang'
    ],
    components:{
        card : () => import ("@/components/award/card")
    },
    data() {
        return {
            tabMenu:[
                {
                    name : 'Sejarah',
                    name_eng : 'history'
                },
                {
                    name : 'Visi Misi',
                    name_eng : 'Vision Mission'
                },
                {
                    name : 'Struktur Perusahaan',
                    name_eng : 'Corporate Structure'
                },
                {
                    name : 'Struktur Organisasi',
                    name_eng : 'organization structure'
                },
                {
                    name : 'Penghargaan',
                    name_eng : "Awards"
                },
                {
                    name : 'Perjalanan',
                    name_eng : "Journey"
                },
            ],
            tabActive: 0,
            imgPreload:require(`@/assets/img/404.jpg`),
            imgActive:false,
            modal:false
        }
    },
    created() {
        let routeParam = this.$route.params.data

        if (routeParam) {
            this.tabActive = routeParam 
        } else {
            this.tabActive = 0
        }
        document.addEventListener('beforeunload', this.destroyTabParam)
	},
    computed: {
        imgHistory(){
			return this.$store.getters['image/imgWtFilter']('history')
		},
        imgVimi(){
			return this.$store.getters['image/imgWtFilter']('vimi')
		},
        imgCorporate(){
			return this.$store.getters['image/imgWtFilter']('corporate')
		},
        imgOrz(){
			return this.$store.getters['image/imgWtFilter']('organization')
		},
        imgJourney(){
			return this.$store.getters['image/imgWtFilter']('journey')
		}
    },
    
    methods: {
        handleTab(i){
            this.tabActive = i
        },
        destroyTabParam() {
            this.tabActive = 0
            // console.log(destroyed);
        },
        showModal(image){
            this.imgActive = image
            this.modal = true
        },
        onToggle() {
            this.modal = !this.modal;
            this.imgActive = ''
        },
    }
    
}
</script>

<style lang="scss">
    .activeTab{
        @apply bg-primary text-grays-50 border-primary hover:text-grays-50;
    }
    .sec-history{
        p {
            @apply mb-6
        }
    }
    .dvVision{
        h2{
            @apply text-3xl font-semibold mb-2 text-center;
        }

        p{
            @apply text-sm lg:text-base;
        }


        ol{
            @apply list-decimal;
        }
    }

    .dvMission{
        h2{
            @apply text-3xl font-semibold mb-2 text-center;
        }

        ul{
            @apply text-sm lg:text-base list-disc pl-5;

            li{
                @apply mb-2;
            }
        }

        ol{
            @apply list-decimal;
        }

        
    }

    .dvCoporate{
        p {
            @apply mb-6
        }
    }

    .dvOrgz{
        p{
            @apply mb-6
        }
    }

     .fade-enter {
        opacity: 0;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 300ms ease-out;
    }

    .fade-leave-to {
        opacity: 0;
    }

    .bglac{
        background-color:rgba(0, 0, 0, 0.597);
    }

    .cursorZom{
        cursor: zoom-in;
    }

    .min-h-24{
        min-height: 130px;
    }
</style>